

























import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { api } from 'plugins'
import { endpoints, toCamelCase, toSnakeCase, showError } from 'utils'
import { Grower, Body, BottomSheetButton } from 'typings'
import { GrowerDialog, MasterItemComponent } from 'components'

const Growers = defineComponent({
  components: {
    GrowerDialog,
    MasterItemComponent
  },
  props: {},
  setup(props, { root }) {
    const growers = ref<Array<Grower>>([])
    const isSorting = ref(false)
    const growerProp = ref({})
    const showGrowerDialog = ref(false)
    const isAdd = ref(true)
    const { $toast } = root

    const loading = ref(false)

    const bottomSheetListButton: Array<BottomSheetButton> = [
      {
        id: 1,
        text: root.$t('master.grower.add') as string,
        classes: [],
        icon: 'mdi-plus',
        action: 'on-add',
        disabled: false
      },
      {
        id: 2,
        text: root.$t('master.grower.sort') as string,
        classes: [],
        icon: 'mdi-sort-ascending',
        action: 'on-sort',
        disabled: false
      },
      {
        id: 4,
        text: root.$t('common.cancel') as string,
        classes: ['red--text'],
        icon: '',
        action: 'on-close',
        disabled: false
      }
    ]

    const getGrowers = async (): Promise<void> => {
      loading.value = true
      try {
        const { data } = await api.get(endpoints.GROWER)
        // need to convert result from snake_case to camelCase
        growers.value = toCamelCase(data)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loading.value = false
      }
      // filter is_parent = false
      // growers.value = growers.value.filter((grower: any) => grower.isParent === false)
    }

    const saveSortOrder = async (body: Body): Promise<void> => {
      const params = toSnakeCase(body)
      try {
        await api.post(`${endpoints.GROWER}sort`, params)
        $toast.success(root.$t('master.msg.save_order_successful'))
        isSorting.value = false
        await getGrowers()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.save_order_failed') as string)
      }
    }

    const openGrowerDialog = (grower: Grower | null): void => {
      if (grower) {
        growerProp.value = JSON.parse(JSON.stringify(grower))
        isAdd.value = false
      } else {
        // default customer prop
        growerProp.value = {
          name: null,
          postalCode: null,
          yomi: null,
          shortName: null,
          tel: null,
          fax: null,
          email: null,
          feeRate: null,
          address1: null,
          address2: null
        }
        isAdd.value = true
      }
      showGrowerDialog.value = true
    }

    const confirmReset = async (): Promise<void> => {
      $toast.info(root.$t('master.customer.customer_list_is_reset'))
      await getGrowers()
    }

    onMounted(getGrowers)

    return {
      growers,
      growerProp,
      loading,
      showGrowerDialog,
      isAdd,
      isSorting,
      getGrowers,
      confirmReset,
      openGrowerDialog,
      saveSortOrder,
      bottomSheetListButton
    }
  }
})

export default Growers
